<template>
	<section id="about-me">
		<div class="py-12"></div>

		<v-container class="text-center">
			<h2 class="display-2 font-weight-bold mb-3">{{ $t('aboutUs') }}</h2>

			<v-responsive class="mx-auto mb-8" width="56">
				<v-divider class="mb-1"></v-divider>

				<v-divider></v-divider>
			</v-responsive>

			<v-responsive
				class="mx-auto title font-weight-light mb-8"
				max-width="720"
			>
				{{ $t('aboutUsText') }}
			</v-responsive>

			<v-avatar class="elevation-12 mb-12 mr-2" size="128">
				<v-img src="@/assets/about-img.jpg"></v-img>
			</v-avatar>

			<v-avatar class="elevation-12 mb-12" size="128">
				<v-img src="@/assets/about-img1.jpg"></v-img>
			</v-avatar>

			<!-- <v-btn
				color="grey"
				href="https://www.mario-nikolov.com"
				target="_blanc"
				outlined
				large
			>
				<span class="grey--text text--darken-1 font-weight-bold">
					{{ $t('portfolio') }}
				</span>
			</v-btn> -->
		</v-container>

		<div class="py-12"></div>
	</section>
</template>

<script>
export default {
	name: 'AppAboutUs',
};
</script>

<style lang="scss" scoped></style>
